<template lang="pug">
.contract-status
  form-input-container(label="Статус контракта")
    el-select.ui-select(
      value-key="id"
      placeholder="Выберите статус контракта"
      :model-value="innerModelValue"
      :disabled="disabled"
      :teleported="false"
      :persistent="false"
      :fit-input-width="false"
      @update:modelValue="setContractStatus"
    )
      .option-groups
        el-option-group(
          v-for="group in contractStatusesGetter"
          :key="group.label"
          :label="group.label"
        )
          el-option(
            v-for="item in group.options"
            :key="item.id"
            :label="item.title"
            :value="item"
          )
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useVModel } from "@vueuse/core";
import { useContractsDialogsOpener } from "~/use/contracts/useContractsDialogsOpener";
import { useApi } from "~/use/api/useApi";

import cloneDeep from "lodash/cloneDeep";
import useManualsStore from "@/stores/manuals/useManualsStore";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";

import type { PropType } from "vue";
import type { StatusI } from "@/stores/manuals/ManualsInterface";
import type { ContractItemInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "ContractStatusSelector",
  components: {
    FormInputContainer,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<ContractItemInterface>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const manualsStore = useManualsStore();
    const { contractStatusesGetter } = manualsStore;

    const interfaceStore = useInterfaceSettingsStore();

    const tender = useVModel<ContractItemInterface>(props, 'modelValue', context.emit);

    const innerModelValue = computed(() => {
      if (tender.value.contract.status) {
        return {
          label: tender.value.contract.status?.title,
          ...tender.value.contract.status,
        }
      }
      return tender.value.contract.status
    })

    const {
      contractUpdating,
      openCalendarDialog,
    } = useContractsDialogsOpener();

    function setContractStatus(status: StatusI) {
      const oldStatus = cloneDeep(props.modelValue?.contract?.status);
      tender.value.contract.status = status;
      updateStatus(status.id, oldStatus);
    }

    function updateStatus(id: number, oldStatus: StatusI) {
      contractUpdating.value = true
      if (interfaceStore.functions.showDatesOnStatusChange) openCalendarDialog(tender.value, true)

      useApi().contracts.patchContractInfo(props.modelValue?.contract?.id, { status: "/api/contracts-statuses/" + id })
        .then((response: any) => tender.value.contract.dates = response?.dates || null)
        .catch(() => {
          useNotificationsStore().showError()
          tender.value.contract.status = oldStatus
        })
        .finally(() => contractUpdating.value = false)
    }

    return {
      innerModelValue,
      tender,
      contractStatusesGetter,
      setContractStatus,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/elements/selector';

.contract-status {
  width: 300px;
}

.option-groups {
  display: flex;
  flex-flow: row;

  :deep(.el-select-group__wrap) {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  :deep(.el-select-dropdown__item) {
    white-space: nowrap !important;
  }
}
</style>
