<template lang="pug">
#main_contracts
  .page-title__wrapper(:class="!enableTemplates && '_margin'")
    h1.main-page-title {{ h1Title }}
    ui-button(
      v-if="!enableTemplates"
      :icon-left="UiIconNames.Icon_Search"
      @click="startNewSearch"
    ) Новый поиск
  .templates-search__wrapper(v-if="enableTemplates")
    template-selector(
      v-model="selectedTemplatesIds"
      @accept="searchContracts"
      @clear:select="clearSelect"
    )
    ui-button(
      size="large"
      style="white-space: nowrap"
      :icon-left="UiIconNames.Icon_Search"
      @click="startNewSearch"
    ) Новый поиск
    icon-button(
      as-button
      button-type="tertiary"
      tooltip-title="Очистить страницу"
      :icon="UiIconNames.Icon_CircleClose"
      @click="clearSelect"
    )

  list-options(
    found-title="Найдено контрактов"
    :options="info.options"
  )
  filter-panel(
    type="filters"
    v-model="preFiltrationForm"
    :columns="columns"
    :has-active-filters="hasActiveFilters"
    :is-downloading="isContractsDownloading"
    @apply="fetchContracts"
    @reset="onResetClick"
    @download="downloadExcel"
  )
  contracts-list(
    :data="data"
    :info="info"
    @reload="updateTemplateOutput"
    @load-next-part="updateTemplateOutput"
  )
</template>

<script lang="ts">
import { computed, defineComponent, watch, ref } from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { getDefaultPreFiltrationFormContract } from "@/utils/getters/defaultFilters";
import { useContractList } from "@/components/pages/contracts/list/useContractList";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { prepareContractData } from "@/utils/api/searchRequests";
import { downloadFromBackend } from "@/utils/download/fileDownloader";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { useFiltersPanel } from "@/use/filters/useFiltersPanel";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";
import { Tabs } from "@/router/tabs";

import TemplateSelector from "@/components/selector/templateSelector/TemplateSelector.vue";
import ContractsList from "@/components/pages/contracts/list/ContractsList.vue";
import ListOptions from "@/components/mainStreamPage/metaInfo/ListOptions.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import FilterPanel from "@/components/ui/filters/FilterPanel.vue";

import useAuthStore from "@/stores/auth/useAuthStore";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "ContractsPanel",
  components: {
    FilterPanel,
    IconButton,
    UiButton,
    ContractsList,
    TemplateSelector,
    ListOptions,
  },
  setup() {

    const route = useRoute();
    const router = useRouter();

    const authStore = useAuthStore();

    const h1Title = computed(() => route.meta.title);
    const isContractsDownloading = ref(false);

    const {
      data,
      info,
      pageStatuses,
      preFiltrationForm,
      selectedTemplatesIds,
      setTab,
      fetchContracts,
      updateTemplateOutput,
      cancelContractsRequests,
    } = useContractList()

    const {
      isTmManager,
      enableTemplates,
    } = useUserAccess();

    const {
      managerCompanyTitle,
    } = useSuperAccount();

    const { storage } = useServiceStorage();

    const defaultFiltrationForm = getDefaultPreFiltrationFormContract();
    const {
      contractColumns: columns,
      hasActiveFilters,
      onResetClick,
    } = useFiltersPanel(preFiltrationForm, defaultFiltrationForm, fetchContracts);

    function startNewSearch() {
      storage.openNewSearch = true;
      router.push({ name: Tabs.Search });
    }

    function searchContracts(payload: { templates: number[], groupId: number|null, templateId: number|null }) {
      selectedTemplatesIds.value = payload.templates || [];
      preFiltrationForm.value = getDefaultPreFiltrationFormContract();
      fetchContracts()
    }

    function downloadExcel() {
      const data = prepareContractData(selectedTemplatesIds.value, pageStatuses.value, preFiltrationForm.value)
      const fileName = `Контракты компании ${ isTmManager.value ? managerCompanyTitle.value : (authStore.company?.title || authStore.me.fio) }`

      downloadFromBackend(isContractsDownloading, "contracts", data, fileName)
    }

    const paramsMode = computed(() => route.name);

    watch(paramsMode, () => {

      if ([Tabs.Contracts.Main, Tabs.Contracts.Conclusion, Tabs.Contracts.Execution, Tabs.Contracts.Completed].includes(route.name as string || '')) {
        setTab(route.name)
      } else {
        return;
      }

      if (route.query.govRuId) {
        preFiltrationForm.value.govRuIds = [route.query.govRuId]
        router.replace({ query: {} });
      }

      fetchContracts()
    }, { immediate: true });

    onBeforeRouteLeave((to: any, from: any, next: any) => {
      cancelContractsRequests();
      next();
    })

    function clearSelect() {
      cancelContractsRequests();

      selectedTemplatesIds.value = [];
      preFiltrationForm.value = getDefaultPreFiltrationFormContract();
      fetchContracts()
    }

    return {
      h1Title,
      data,
      info,
      columns,
      hasActiveFilters,
      enableTemplates,
      preFiltrationForm,
      defaultFiltrationForm,
      isContractsDownloading,
      selectedTemplatesIds,
      clearSelect,
      startNewSearch,
      searchContracts,
      fetchContracts,
      updateTemplateOutput,
      onResetClick,
      downloadExcel,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import "@/assets/styles/pages/main";

#main_contracts {
  @include page-view;
}
</style>
